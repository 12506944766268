import React, { useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import MainLayout from '../MainLayout'
import FormControl from '@material-ui/core/FormControl'
import GetAppIcon from '@material-ui/icons/GetApp'
import ListCustomerComponent from '../../components/ListCustomer'
import FormDialog from '../../components/FormDialog'
import ButtonComponent from '../../components/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormLabel from '@material-ui/core/FormLabel'
import { Button, Chip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import types from '../../constants/types'
import moment from 'moment'
import IMAGES from '../../images'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteIcon from '@material-ui/icons/Delete'
import Loading from '../../components/Loading'
import { getFanpageDetail } from 'views/Fanpage/FanpageActions'
import { useDispatch, useSelector } from 'react-redux'
import Flex from 'components/Flex'
import BaseSelectFile from 'components/BaseSelectFile'
import cn from 'classnames'
import { selectFilterCus, selectImportResult } from './CustomerSelectors'
import Tour from 'reactour'
import { selectSearchUidName } from './CustomerSelectors'
import { getIsGuideByKey, GUIDE_TYPE_ENUM, setIsGuideByKey } from 'utils/helper'
import { selectGuideListByKey } from 'stores/config/config.selector'
import ModalResultImport from './item/ModalResultImport'
import { useTranslation } from 'react-i18next'
import MessengerButton from './../Home/items/MessengerButton'
import TagPreviewItem from '../../components/TagPreviewItem'
import { colorDir1, getRandomTag } from '../../components/TagPreviewModal'
import VirtualScroll from '../../components/VirtualScroll'

const useStyles = makeStyles((theme) => ({
  wrapSelectFanpage: {
    marginTop: 30,
    // width: 'calc(100% - 15px)',
    width: '100%',
    '& h4': {
      marginBottom: 5,
      color: '#05AE34',
      fontSize: 16,
    },
  },
  wrapSelect: {
    width: '100%',
    marginTop: '12px',
    marginBottom: '12px',
  },
  select: {
    // margin: "5px 0px !important",
  },
  wrapSelectLabel: {
    zIndex: 1,
    fontSize: 16,
    paddingLeft: 10,
    marginTop: 7,
    '&.MuiFormLabel-filled': {
      display: 'none !important',
    },
    '&.Mui-focused': {
      display: 'none !important',
    },
  },

  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#05AE34',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  wrapRadioGroup: {
    display: 'inline-block',
    flexWrap: 'inherit',
    flexDirection: 'inherit',
    marginLeft: 15,
    fontSize: '16px',
    marginTop: 10,
  },
  // wrapperPopup: {
  //   "& h4": {
  //     color: "#05AE34",
  //     paddingLeft: 15,
  //   },
  //   "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
  //     top: "-25px",
  //     color: "rgba(0, 0, 0, 0.87) !important",
  //     width: "100%",
  //     fontSize: "16px",
  //     transform: "inherit",
  //   },
  //   "& .MuiInputLabel-filled": {
  //     top: "-25px",
  //     color: "rgba(0, 0, 0, 0.87) !important",
  //     width: "100%",
  //     fontSize: "16px",
  //     transform: "inherit",
  //   },
  //   "& .MuiFilledInput-input": {
  //     padding: "10px 12px 10px 2px !important",
  //   },
  // },
  // radioGroup: {
  //   flexWrap: "inherit",
  //   flexDirection: "inherit",
  //   "& label": {
  //     float: "left",
  //   },
  // },
  // textField: {
  //   width: "100%",
  //   padding: "0px 15px",
  //   margin: "35px 0px 10px 0px",
  //   "& .MuiInputLabel-filled": {
  //     marginLeft: 15,
  //   },
  // },
  formControlCheckbox: {
    margin: '15px 0px 0px 15px',
    width: 'calc(100% - 30px)',
    '& .MuiFormLabel-root': {
      marginBottom: 5,
      fontSize: 16,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#05AE34',
    },
    '& .MuiFormLabel-root': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 16,
    },
  },
  wrapButton: {
    width: '100%',
    display: 'inline-block',
  },
  button: {
    display: 'inline-block',
    width: 130,
    float: 'right',
    margin: '30px 15px 30px 0px',
  },
  title: {
    color: '#05AE34',
  },
  wrapButtonImport: {
    textAlign: 'right',
  },
  wrapBox: {
    padding: '15px 42px 30px 15px',
  },
  buttonChange: {
    margin: '0px 0px 0px 10px',
  },
  importInput: {
    margin: '15px 0px 30px 0px',
  },
  inboxDate: {
    left: 15,
    top: -5,
    width: 200,
    marginTop: 25,
    // marginwrapperPopupwrapperPopupTop: 25,
    '& label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '16px',
      transform: 'inherit',
      top: '-10px',
      width: '100%',
    },
    '& .MuiInputBase-formControl': {
      width: 140,
    },
    '& $root': {
      width: 120,
    },
    '& .MuiInputBase-input': {
      width: 120,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
      boxSizing: 'border-box',
    },
    '& .MuiInputBase-input::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
  },
  inboxTime: {
    left: 15,
    top: -5,
    width: 200,
    marginTop: 25,
    // marginwrapperPopupwrapperPopupTop: 25,
    '& label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '16px',
      transform: 'inherit',
      top: '-10px',
      width: '100%',
    },
    '& .MuiInputBase-formControl': {
      width: 140,
    },
    '& $root': {
      width: 120,
    },
  },
  inboxTimeLast: {
    right: 30,
    position: 'absolute',
    top: -5,
    marginTop: 25,
    // marginwrapperPopupwrapperPopupTop: 25,
    '& label': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      fontSize: '16px',
      transform: 'inherit',
      top: '-10px',
      width: '100%',
    },
    '& .MuiInputBase-formControl': {
      width: 140,
    },
    '& $root': {
      width: 120,
    },

    '& .MuiInputBase-input::-webkit-calendar-picker-indicator': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
  },
  wrapperLoading: {
    height: 100,
    position: 'relative',
    textAlign: 'center',
    top: -20,
    '& img': {
      height: 100,
      position: 'absolute',
      top: 0,
      left: 'calc(50% - 50px)',
      width: 100,
    },
    '& p': {
      position: 'absolute',
      bottom: -11,
      textAlign: 'center',
      width: '100%',
    },
  },
  noData: {
    marginTop: 15,
    marginBottom: 10,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  addTag: {
    width: '100%',
    marginBottom: 15,
  },
  wrapCheckbox: {
    position: 'relative',
    '&:hover': {
      '& $delete': {
        display: 'inline-block',
      },
    },
  },
  delete: {
    display: 'none',
    position: 'absolute',
    right: '-8%',
    top: '50%',
    transform: 'translateY(-50%)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  wrapButtonPopup: {
    textAlign: 'right',
    paddingBottom: 30,
    paddingRight: 15,
  },
  tagFormGroup: {
    // height: '200px',
    // overflowY: 'auto',
    // overflowX: 'hidden',
    display: 'initial',
    marginTop: '15px',
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
  },
  fromToIcon: {
    position: 'absolute',
    top: '45px',
    left: '180px',
  },
}))

function StyledRadio(props) {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  )
}

const CustomerView = (props) => {
  const classes = useStyles()

  const {
    getFanpage,
    getCustomer,
    fanpageFull,
    customer,
    onDetail,
    onEdit,
    detail,
    getListTagFacebook,
    onFanpageSelected,
    fanpageSelected,

    getListTagBombot,
    onExport,
    fileUpload,
    loadingImport,
    importSuccess,
    onSubmitAddTag,
    loading,
    onCreate,
    getCustomerAll,
    customerAll,
    customerCheck,
    onCustomerCheck,
    onCustomerCheckReset,
    createCustomerSucss,
    resetCustomer,
    messageImport,
  } = props
  const [data, setData] = useState([])
  const [isPopup, setIsPopup] = useState(false)
  const [detailState, setDetailState] = useState({})
  const [listTagFacebook, setListTagFacebook] = useState([])
  const [listTagBombot, setListTagBombot] = useState([])
  const [tagFbSelectedAll, setTagFbSelectedAll] = useState(false)
  const [tagBbSelectedAll, setTagBbSelectedAll] = useState(false)
  const [isActions, setIsActions] = useState(null)
  const [file, setFile] = useState({})
  const [isImportPopup, setIsImportPopup] = useState(false)

  const [uid, setUid] = useState(null)
  const [nameFacebook, setNameFacebook] = useState(null)
  const [lastInboxTime, setLastInboxTime] = useState(null)
  const [lastInboxTimeTo, setLastInboxTimeTo] = useState(null)

  // const [tagFacebook, setTagFacebook] = useState([]);
  // const [tagBombot, setTagBombot] = useState([]);
  const [gender, setGender] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [isAddTag, setIsAddTag] = useState(false)
  const [addTag, setAddTag] = useState('')
  const [listBombotTag, setListBombotTag] = useState([])
  const [isConfirmDeleteTag, setIsConfirmDeleteTag] = useState(false)
  const [itemDelete, setItemDelete] = useState({})
  const [isCreateCampaign, setIsCreateCampaign] = useState(false)
  const [paramsFilter, setParamsFilter] = useState(undefined)
  const [messageAddTag, setMessageAddTag] = useState('')
  const [urlSearchParamPageId, setUrlSearchParamPageId] = useState(undefined)
  const [urlSearchParamSearch, setUrlSearchParamSearch] = useState(undefined)
  const [urlSearchParamPage, setUrlSearchParamPage] = useState(
    types.PAGINATION.PAGE
  )
  const [urlSearchParamSize, setUrlSearchParamSize] = useState(
    types.PAGINATION.SIZE
  )

  const [lastCustomerInboxTimeTo, setLastCustomerInboxTimeTo] = useState(null)
  const [lastCustomerInboxTime, setLastCustomerInboxTime] = useState(null)

  const importResult = useSelector(selectImportResult)
  const searchUidName = useSelector(selectSearchUidName)

  const [urlSearchParamSort, setUrlSearchParamSort] = useState('createdAt,desc')
  const [message, setMessage] = useState('')
  const [typeIdentif, setTypeIdentif] = useState('CONVERSATION_ID')
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedPageId, setSelectedPageId] = useState(
    fanpageSelected.id || null
  )

  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.customer_manager)
  )

  const { t } = useTranslation()

  const [tourConfig, setTourConfig] = useState(null)

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.customer_manager) &&
      tourOverview?.length
    ) {
      setTourConfig(tourOverview)
      setIsGuideByKey(GUIDE_TYPE_ENUM.customer_manager)
    }
  }, [tourOverview])

  useEffect(() => {
    if (!importSuccess) {
      setMessage(messageImport?.message)
    }
  }, [messageImport])

  useEffect(() => {
    if (fanpageFull) {
      const searchParam = new URLSearchParams(window.location.search)
      let pageId = searchParam.get('pageId')
      const page = searchParam.get('page')
      const size = searchParam.get('size')
      const sort = searchParam.get('sort')
      const search = searchParam.get('search')
      if (pageId) {
        setUrlSearchParamPageId(pageId)
      }
      if (!pageId && fanpageFull[0]) {
        pageId = fanpageFull[0].id
        setUrlSearchParamPageId(fanpageFull[0].id)
      }
      if (page) {
        setUrlSearchParamPage(page)
      }
      if (size) {
        setUrlSearchParamSize(size)
      }
      if (sort) {
        setUrlSearchParamSort(sort)
      }
      if (search) {
        setUrlSearchParamSearch(search)
      }
      const fanpageCurrent = fanpageFull.find((item) => item.id == pageId)

      if (fanpageCurrent) {
        onFanpageSelected(fanpageCurrent)
        getCustomer({
          page: page,
          size: size,
          search: `${
            urlSearchParamSearch
              ? urlSearchParamSearch
              : `pageId==${fanpageCurrent.id ? fanpageCurrent.id : undefined}`
          }`,
          sort: sort,
        })
        getCustomerAll({
          search: `pageId==${
            fanpageCurrent.id ? fanpageCurrent.id : undefined
          }`,
          sort: 'lastInboxTime,desc',
        })
      } else {
        resetCustomer()
        onFanpageSelected({})
      }
    }
  }, [fanpageFull])

  const filterCus = useSelector(selectFilterCus)

  useEffect(() => {
    if (filterCus?.search_string) {
      let searchQuery = filterCus?.search_string

      if (!_.isEmpty(searchUidName)) {
        searchQuery += `;uid|name==` + searchUidName
      }

      getCustomer({
        page: 0,
        size: 10,
        search: searchQuery,
        sort: 'lastInboxTime,desc',
      })
    }
  }, [filterCus?.search_string])

  useEffect(() => {
    if (isPopup) {
      let listTagBombotTmp = sortListTag(getListTagBombot)
      setListTagBombot(listTagBombotTmp)
    }
  }, [getListTagBombot, isPopup])

  useEffect(() => {
    if (isPopup) {
      let listTagFbTmp = sortListTag(getListTagFacebook)
      setListTagFacebook(listTagFbTmp)
    }
  }, [getListTagFacebook, isPopup])

  useEffect(() => {
    if (importSuccess) {
      getCustomer({
        page: types.PAGINATION.PAGE,
        size: types.PAGINATION.SIZE,
        search: `pageId==${fanpageSelected.id}`,
        sort: 'lastInboxTime,desc',
      })
      setIsImportPopup(false)
    }
  }, [importSuccess])

  useEffect(() => {
    setData(customer)
  }, [customer])

  useEffect(() => {
    setDetailState(detail)
    setUid(detail.uid)
    setNameFacebook(detail.name)
    setLastInboxTime(detail.updatedAt)
    setGender(detail.gender)
  }, [detail])

  useEffect(() => {
    const params = {
      // page: page,
      // size: rowsPerPage,
      // search: search ? search : undefined,
    }
    getFanpage(params)
    setData([])
    onCustomerCheckReset([])
  }, [])

  const handleChangeFanpage = (key, value) => {
    switch (key) {
      case 'fanpage':
        if (value) {
          onFanpageSelected(value)
          getCustomer({
            page: types.PAGINATION.PAGE,
            size: types.PAGINATION.SIZE,
            search: `pageId==${value.id ? value.id : undefined}`,
            sort: 'lastInboxTime,desc',
          })
          getCustomerAll({
            search: `pageId==${value.id ? value.id : undefined}`,
            sort: 'lastInboxTime,desc',
          })

          const searchParam = new URLSearchParams(window.location.search)
          searchParam.set('page', types.PAGINATION.PAGE)
          searchParam.set('size', types.PAGINATION.SIZE)
          searchParam.set('pageId', `${value.id ? value.id : undefined}`)
          searchParam.set('sort', 'lastInboxTime,desc')
          searchParam.delete('search')
          searchParam.delete('query')
          const newSearchParam = searchParam.toString()
          history.push({
            pathname: window.location.pathname,
            search: newSearchParam,
          })
          dispatch(getFanpageDetail(value.id))
        }
        break
      default:
        break
    }
  }

  const handleCheckedAllTagFacebook = (event) => {
    const items = []
    if (tagFbSelectedAll) {
      ;(listTagFacebook || []).map((item) => {
        items.push({
          ...item,
          checked: false,
        })
      })
    } else {
      ;(listTagFacebook || []).map((item) => {
        items.push({
          ...item,
          checked: true,
        })
      })
    }
    setListTagFacebook(items)
    setTagFbSelectedAll(!tagFbSelectedAll)
  }

  const handleCheckedAllTagBombot = () => {
    if (tagBbSelectedAll) {
      const updatedTags = listTagBombot.map((tag) => ({
        ...tag,
        checked: false,
      }))
      setListTagBombot(updatedTags)
    } else {
      const updatedTags = listTagBombot.map((tag) => ({
        ...tag,
        checked: tag.name !== 'Không có tag',
      }))
      setListTagBombot(updatedTags)
    }
    setTagBbSelectedAll(!tagBbSelectedAll)
  }

  const handleCheckedTagBombot = (item) => {
    setTagBbSelectedAll(false)

    if (item.name === 'Không có tag') {
      const updatedTags = listTagBombot.map((it) => ({
        ...it,
        checked: it.name === 'Không có tag',
      }))
      setListTagBombot(updatedTags)
    } else {
      const updatedTags = listTagBombot.map((it) => {
        if (it.name === 'Không có tag') {
          return { ...it, checked: false }
        }
        if (it.id === item.id) {
          return { ...it, checked: !it.checked }
        }
        return it
      })
      setListTagBombot(updatedTags)
    }
  }

  const handleCheckedTagFacebook = (item) => {
    setTagFbSelectedAll(false)

    if (item.name === 'Không có tag') {
      const updatedTags = listTagFacebook.map((it) => ({
        ...it,
        checked: it.name === 'Không có tag',
      }))
      setListTagFacebook(updatedTags)
    } else {
      const updatedTags = listTagFacebook.map((it) => {
        if (it.name === 'Không có tag') {
          return { ...it, checked: false }
        }
        if (it.id === item.id) {
          return { ...it, checked: !it.checked }
        }
        return it
      })
      setListTagFacebook(updatedTags)
    }
  }

  const handleClosePoup = () => {
    setIsPopup(false)
    setLastInboxTimeTo(null)
    setLastInboxTime(null)
    setLastCustomerInboxTimeTo(null)
    setLastCustomerInboxTime(null)
  }

  const handleDetail = (item) => {
    setIsActions(types.ACTIONS.DETAIL)
    onDetail(item)
    setIsPopup(true)
  }

  const handleEdit = (item, pagination) => {
    setIsActions(types.ACTIONS.EDIT)
    onDetail(item)
    setIsPopup(true)
    setPagination(pagination)
    // handleSubmitActions(item)
  }

  const handleExport = () => {
    console.log('####handleExport customerCheck', customerCheck)
    if (!_.isEmpty(fanpageSelected)) {
      let excludeArr = []
      customerCheck.map((item) => {
        if (item.checked) {
          return excludeArr.push(item.uid)
        }
      })
      onExport({ fanpageSelected: fanpageSelected, exclude: excludeArr })
    }
  }

  const handleImport = () => {
    setIsImportPopup(true)
    setMessage('')
  }

  const handleFilter = (pagination) => {
    onDetail({})
    setIsActions(types.ACTIONS.FILTER)
    setIsPopup(true)
    setLastInboxTimeTo(null)
    setLastInboxTime(null)
    setLastCustomerInboxTimeTo(null)
    setLastCustomerInboxTime(null)
    setPagination(pagination)
  }
  const handleDownloadExcel = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}`
    window.open(`${baseUrl}api/v1.0/customer/customer-template.xlsx`, '_blank')
  }
  const handleCloseImport = () => {
    setIsImportPopup(false)
  }

  const sortListTag = (listTag) => {
    const noTag = (listTag || []).find((item) => item.name === 'Không có tag')
    const otherTags = (listTag || []).filter(
      (item) => item.name !== 'Không có tag'
    )
    const attachedTags = otherTags.filter((item) => item.checked)
    const unattachedTags = otherTags.filter((item) => !item.checked)

    attachedTags.sort((a, b) => a.name.localeCompare(b.name))
    unattachedTags.sort((a, b) => a.name.localeCompare(b.name))

    if (noTag) {
      return [noTag, ...attachedTags, ...unattachedTags]
    }

    return [...attachedTags, ...unattachedTags]
  }

  const handleSubmitOpenExtension = () => {
    let url =
      types.URL.FACEBOOK +
      `&token=${localStorage.getItem('token') || null}` +
      '&tab=scan'

    if (selectedPageId) {
      url += `&pageId=${selectedPageId}`
    }

    window.open(url, '_blank')
  }

  useEffect(() => {
    if (!fanpageSelected.id || selectedPageId) return
    setSelectedPageId(fanpageSelected.id)
  }, [fanpageSelected])

  //  WORKING CODE ---------------------
  const handleSubmitImportExcel = (event) => {
    const params = {
      file: file,
      fanpage: fanpageSelected,
      type: typeIdentif,
    }
    fileUpload(params)
  }
  //  WORKING CODE ---------------------

  const handleFile = (event) => {
    setFile(event.target.files[0])
  }

  const handleChange = (key, value) => {
    switch (key) {
      case 'uid':
        setUid(value)
        break
      case 'nameFacebook':
        setNameFacebook(value)
        break
      case 'lastInboxTime':
        setLastInboxTime(value)
        break
      case 'lastInboxTimeTo':
        setLastInboxTimeTo(value)
        break
      case 'lastCustomerInboxTime':
        setLastCustomerInboxTime(value)
        break
      case 'lastCustomerInboxTimeTo':
        setLastCustomerInboxTimeTo(value)
        break
      case 'gender':
        setGender(value)
        break
      case 'addTagBombot':
        setAddTag(value)
        break
      case 'identification':
        setTypeIdentif(value)
        break
      default:
        break
    }
  }

  const handleSubmitActions = () => {
    let fbTagsArr = []
    let bombotTagsArr = []
    let fbTagsArrAll = []
    let bombotTagsArrAll = []
    let notInTagBombot = false
    let notInTagFacebook = false

    listTagBombot.map((it) => {
      if (it.checked) {
        bombotTagsArr.push(it.id)
      }
      if (it.checked && it.id == 'not_in_tag') {
        notInTagBombot = true
      }
      if (it.id != 'not_in_tag' && !it.checked) {
        bombotTagsArrAll.push(it.id)
      }
    })

    listTagFacebook.map((it) => {
      if (it.checked) {
        fbTagsArr.push(it.id)
      }
      if (it.checked && it.id == 'not_in_tag') {
        notInTagFacebook = true
      }
      if (it.id != 'not_in_tag' && !it.checked) {
        fbTagsArrAll.push(it.id)
      }
    })
    // console.log('notInTagFacebook', notInTagBombot, bombotTagsArr, bombotTagsArr.length)
    if (isActions == types.ACTIONS.EDIT) {
      const params = {
        id: detail.id,
        uid: uid,
        name: nameFacebook,
        pageId: detail.pageId,
        gender: gender,
        fbTags: fbTagsArr,
        bombotTags: bombotTagsArr,
        facebookTags: fbTagsArr,
        lastInboxTime: detail.lastInboxTime,
        lastCustomerInboxTime: detail.lastCustomerInboxTime,
      }
      const searchParam = new URLSearchParams(window.location.search)
      const getSearch = searchParam.get('search')
      onEdit(params).then((res) => {
        if (res) {
          getCustomer({
            page: urlSearchParamPage,
            size: urlSearchParamSize,
            search: `${
              getSearch ? getSearch : `pageId==${fanpageSelected.id}`
            }`,
            // search: `pageId==${
            //   urlSearchParamPageId ? urlSearchParamPageId : fanpageSelected.id
            // }`,
            sort: urlSearchParamSort
              ? urlSearchParamSort
              : 'lastInboxTime,desc',
          })
        }
      })
      // getCustomer(pagination);
    } else if (isActions == types.ACTIONS.FILTER) {
      let paramsSearch = `pageId==${fanpageSelected.id}`
      if (uid != undefined) {
        paramsSearch = `${paramsSearch};uid==` + uid
      }
      if (nameFacebook != undefined) {
        paramsSearch = `${paramsSearch};name==` + nameFacebook
      }
      if (gender != undefined && gender != 'all') {
        paramsSearch = `${paramsSearch};gender==` + _.upperCase(gender)
      }
      if (lastInboxTime) {
        paramsSearch = `${paramsSearch};lastInboxTime=bw=${lastInboxTime},${
          lastInboxTimeTo ? lastInboxTimeTo : lastInboxTime
        }`
      }
      if (lastCustomerInboxTime) {
        paramsSearch = `${paramsSearch};lastCustomerInboxTime=bw=${lastCustomerInboxTime},${
          lastCustomerInboxTimeTo
            ? lastCustomerInboxTimeTo
            : lastCustomerInboxTime
        }`
      }
      if (fbTagsArr.length > 0 && fbTagsArr.length < listTagFacebook.length) {
        paramsSearch = `${paramsSearch};fbTags=${
          notInTagFacebook ? 'notin' : 'in'
        }=${notInTagFacebook ? fbTagsArrAll.toString() : fbTagsArr.toString()}`
      }
      if (
        bombotTagsArr.length > 0 &&
        bombotTagsArr.length < listTagBombot.length
      ) {
        paramsSearch = `${paramsSearch};bombotTags=${
          notInTagBombot ? 'notin' : 'in'
        }=${
          notInTagBombot
            ? bombotTagsArrAll.toString()
            : bombotTagsArr.toString()
        }`
      }
      const params = {
        page: pagination.page,
        size: pagination.size,
        search: paramsSearch,
        sort: 'lastInboxTime,desc',
      }
      // {"lastInboxTime":["2020-09-30T17:00:00.000Z","2021-05-10T17:00:00.000Z"],"gender":"all","bombotTags":["152053205","425833478"],"fbTags":[],"pageId":"472971019831648"}
      // query: JSON.stringify({
      //   lastInboxTime: [lastInboxTime, lastInboxTimeTo],
      //   gender: gender,
      //   pageId: fanpageSelected.id,
      //   bombotTags: Object.keys(bombotTagsArr) || [],
      //   fbTags: Object.keys(fbTagsArr) || [],
      // }),

      const paramsFilter = JSON.stringify({
        lastInboxTime: [lastInboxTime, lastInboxTimeTo],
        lastCustomerInboxTime: [lastCustomerInboxTime, lastCustomerInboxTimeTo],
        gender: gender,
        pageId: fanpageSelected.id,
        bombotTags: bombotTagsArr || [],
        fbTags: fbTagsArr || [],
      })
      const searchParam = new URLSearchParams(window.location.search)
      searchParam.set('search', paramsSearch)
      searchParam.delete('query')
      searchParam.set('query', paramsFilter)
      searchParam.set('page', pagination.page)
      const newSearchParam = searchParam.toString()
      history.push({
        pathname: window.location.pathname,
        search: newSearchParam,
      })
      setParamsFilter(paramsFilter)
      getCustomer(params)
    }
    setIsPopup(false)
  }
  const handleOpenAddTag = () => {
    setMessageAddTag('')
    setAddTag('')
    setIsAddTag(true)
  }
  const handleCloseAddTag = () => {
    setIsAddTag(false)
  }
  const handleDeteleTag = (item) => {
    setItemDelete(item)
    setIsConfirmDeleteTag(true)
  }

  const handleSubmitAddTag = () => {
    if (!/\S/.test(addTag)) {
      setMessageAddTag(t('update.noti.enter_tag'))
    } else {
      setMessageAddTag('')
      const checkAddTag = (obj) => obj.name === addTag
      const checkTagExist = listTagBombot.some(checkAddTag)
      if (checkTagExist) {
        setMessageAddTag(t('update.noti.tag_existed'))
      } else {
        if (addTag) {
          let bombotTags = listTagBombot.length > 0 ? listTagBombot : []
          const filtered = bombotTags.filter(function (el) {
            return el.id != 'not_in_tag'
          })
          let dataBombotTag = [...filtered, { name: addTag }]
          let facebookTags =
            fanpageSelected && fanpageSelected.fbTags
              ? fanpageSelected && fanpageSelected.fbTags
              : []
          const params = {
            id: fanpageSelected.id,
            name: fanpageSelected.name,
            fbTags: facebookTags,
            bombotTags: dataBombotTag,
          }
          onSubmitAddTag(params)
          setAddTag('')
          setIsAddTag(false)
        }
      }
    }

    //   if (addTag)
    //   if(listTagBombot.indexOf(addTag) !== -1){
    //     alert("Value exists!")
    // } else{
    //     alert("Value does not exists!")
    // }
    // if (!/\S/.test(addTag)) {
    //   setMessageAddTag("Vui lòng nhập tên tag")
    // } else {
    //   setMessageAddTag("")
    //   if (addTag) {
    //     let bombotTags = listTagBombot.length > 0 ? listTagBombot : [];
    //     const filtered = bombotTags.filter(function(el) { return el.id != "not_in_tag" });
    //     let dataBombotTag = [...filtered, { name: addTag }];
    //     let facebookTags =
    //       fanpageSelected && fanpageSelected.fbTags
    //         ? fanpageSelected && fanpageSelected.fbTags
    //         : [];
    //     const params = {
    //       id: fanpageSelected.id,
    //       name: fanpageSelected.name,
    //       fbTags: facebookTags,
    //       bombotTags: dataBombotTag,
    //     };
    //     onSubmitAddTag(params);
    //     setAddTag("");
    //     setIsAddTag(false);
    //   }
    // }
  }
  const handleCloseConfirmDeleteTag = () => {
    setIsConfirmDeleteTag(false)
  }
  const handleConfirmDeleteTag = () => {
    let bombotTags = listTagBombot.length > 0 ? listTagBombot : []
    const filtered = bombotTags.filter(function (el) {
      return el.id != 'not_in_tag'
    })
    let dataBombotTag = filtered.filter(function (it) {
      return it.id != itemDelete.id
    })
    let facebookTags =
      fanpageSelected && fanpageSelected.fbTags
        ? fanpageSelected && fanpageSelected.fbTags
        : []
    const params = {
      id: fanpageSelected.id,
      name: fanpageSelected.name,
      fbTags: facebookTags,
      bombotTags: dataBombotTag,
    }

    onSubmitAddTag(params)
    setAddTag('')
    setIsAddTag(false)
    setIsConfirmDeleteTag(false)
  }

  const handleParamsPagination = (params) => {
    setUrlSearchParamPage(params.page - 1)
    setUrlSearchParamSize(params.size)
    setUrlSearchParamPageId(params.pageId)
  }

  return (
    <MainLayout>
      <Tour
        steps={tourConfig}
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t('common.btn.close')}
          </Button>
        }
        isOpen={tourConfig != null}
        accentColor="#05ae34"
        onRequestClose={() => setTourConfig(null)}
      />
      {/* <input type="file" onChange={fileHandler} style={{"padding":"10px"}} /> */}
      {loading && <Loading />}
      <FormDialog isOpen={isAddTag} handleClose={() => handleCloseAddTag()}>
        <div className={cn(classes.wrapBox, 'p-4')}>
          <h4 className={classes.title}>
            {t('customer_management.add_bombot_tag.insert_tag_name')}
          </h4>
          <TextField
            error={false}
            label={t('customer_management.add_bombot_tag.insert_tag_name')}
            variant="outlined"
            size="small"
            className={classes.addTag}
            defaultValue={addTag}
            onChange={(event) =>
              handleChange('addTagBombot', event.target.value)
            }
          />
          {messageAddTag && (
            <p style={{ textAlign: 'center', color: 'red' }}>{messageAddTag}</p>
          )}
          <div className={classes.wrapButtonImport}>
            <ButtonComponent
              className={cn(classes.buttonChange, 'btn--rounder')}
              title={t('common.btn.save')}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitAddTag()}
              disabled={loadingImport ? true : false}
            />
          </div>
        </div>
      </FormDialog>
      <FormDialog
        isOpen={isConfirmDeleteTag}
        // handleClose={() => handleCloseInviteCodePopup()}
      >
        <div className={classes.wrapChangePassword}>
          {/* <h4 className={classes.titlePopup}>
            Xác nhân
          </h4> */}
          <p style={{ padding: '15px 15px 0px 15px', textAlign: 'center' }}>
            {t('update.delete_tag.desc')}?
          </p>
          <div className={cn(classes.wrapButtonPopup, 'mt-4')}>
            <ButtonComponent
              size="small"
              className="btn--rounder mr-2"
              title={t('common.btn.cancel')}
              backgroundColor="#BDBDBD"
              color="white"
              handleClickButton={() => handleCloseConfirmDeleteTag()}
            />
            <ButtonComponent
              size="small"
              className="btn--rounder"
              variant="contained"
              title={t('common.btn.confirm')}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleConfirmDeleteTag()}
            />
          </div>
        </div>
      </FormDialog>
      <FormDialog
        isOpen={isImportPopup}
        handleClose={() => handleCloseImport()}
      >
        <div className={classes.wrapBox}>
          <Flex justify="space-between" align="center">
            <h4 className={classes.title}>
              {t('customer_management.import_customer.title')}
            </h4>
            <ButtonComponent
              className="btn--rounder btn--primary mr-2"
              title={
                <Flex align="center">
                  <GetAppIcon />
                  <p className="ml-2">{t('common.btn.download_template')}</p>
                </Flex>
              }
              backgroundColor="#FF8000"
              color="white"
              handleClickButton={() => handleDownloadExcel()}
            />
          </Flex>
          <p className="mt-2">
            {t('customer_management.import_customer.description')}
          </p>
          <div className="my-4">
            <BaseSelectFile
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              variant="outlined"
              fileNameSelected={file?.name}
              onChange={handleFile}
            />
          </div>
          {/* <input
            className={classes.importInput}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
          /> */}

          <FormControl component="fieldset" className="field_identification">
            <p className="fw-500 fz-16 mb-2">
              {t('customer_management.import_customer.choose_option')}
            </p>
            <RadioGroup
              value={typeIdentif}
              onChange={(_, data) => handleChange('identification', data)}
            >
              <FormControlLabel
                value="CONVERSATION_ID"
                control={<Radio />}
                label="Conversation_ID"
              />

              <FormControlLabel
                value="UID"
                control={<Radio />}
                label={t('customer_management.import_customer.uid')}
              />

              <FormControlLabel
                value="PHONE"
                control={<Radio />}
                label={t('customer_management.import_customer.phone')}
              />
              <FormControlLabel
                value="FULLNAME_GENGER"
                control={<Radio />}
                label={t('customer_management.import_customer.name_gender')}
              />
              <FormControlLabel
                value="FULLNAME_PHONE"
                control={<Radio />}
                label={t('customer_management.import_customer.name_phone')}
              />
              <FormControlLabel
                value="FULLNAME_LASTINBOX"
                control={<Radio />}
                label={t(
                  'customer_management.import_customer.name_last_inbox_time'
                )}
              />
              <FormControlLabel
                value="FULLNAME_LASTCUSTOMERINBOX"
                control={<Radio />}
                label={t(
                  'customer_management.import_customer.name_last_customer_inbox_time'
                )}
              />
            </RadioGroup>
          </FormControl>
          {loadingImport && (
            <div className={classes.wrapperLoading}>
              <img src={IMAGES.LOADING} />
              <p>{t('update.import_customer.loading')}</p>
            </div>
          )}
          {!loadingImport && message && (
            <p
              style={{
                color: 'red',
                textAlign: 'center',
                marginTop: 0,
                marginBottom: 30,
              }}
            >
              {message}
            </p>
          )}
          <div className={classes.wrapButtonImport}>
            <ButtonComponent
              className="btn--rounder btn--primary mr-2"
              title={t('common.btn.cancel')}
              backgroundColor="#BDBDBD"
              color="white"
              handleClickButton={() => handleCloseImport()}
              disabled={loadingImport ? true : false}
            />
            <ButtonComponent
              className="btn--rounder btn--primary--filled mr-2"
              title={t('common.btn.save')}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitImportExcel()}
              disabled={loadingImport ? true : false}
            />
          </div>
        </div>
      </FormDialog>
      <div className={classes.wrapSelectFanpage}>
        <h4>Fanpage</h4>

        <FormControl
          className={classes.wrapSelect}
          style={{ position: 'relative' }}
        >
          <div
            className="w-full"
            id="fanpage_select"
            style={{
              width: '100%',
              height: 32,
              position: 'absolute',
              top: 5,
              zIndex: -99,
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: '3px',
            }}
          >
            {/* thanh chọn fanpage */}
            <Autocomplete
              freeSolo
              className=""
              style={{ flexGrow: 1 }}
              options={fanpageFull}
              onChange={(_, data) => {
                setSelectedPageId(data?.id || null)
                handleChangeFanpage('fanpage', data)
              }}
              getOptionLabel={(option) => option?.name || ''}
              value={fanpageSelected || null}
              renderInput={(params) => {
                console.log('params ==>', params)
                return (
                  <TextField
                    {...params}
                    size="small"
                    className="bg-white"
                    label={t('customer_management.choose_page')}
                    variant="outlined"
                    style={{ width: '100%' }}
                    onChange={
                      (event) => {
                        console.log(
                          'event.target.value ==>',
                          event.target.value
                        )
                      }
                      // handleChangeFanpage('fanpage', event.target.value)
                    }
                  />
                )
              }}
            />

            {/* button quet khach hang */}
            <div className="w-full">
              <ButtonComponent
                id="scan_customer"
                className={cn(
                  classes.buttonFilter,
                  'btn--rounder',
                  'btn--primary--filled',
                  'ml-2'
                )}
                title={
                  <p>
                    <SearchIcon />
                    <span>{t('common.btn.scan_customer')}</span>
                  </p>
                }
                backgroundColor="#05AE34"
                color="white"
                handleClickButton={() => handleSubmitOpenExtension()}
              />
            </div>
          </div>

          {/* <InputLabel
            className={classes.wrapSelectLabel}
            htmlFor="grouped-select"
          >
            Chọn fanpage
          </InputLabel> */}
          {/* <Select
            onChange={(event) =>
              handleChangeFanpage("fanpage", event.target.value)
            }
          >
            {(fanpageFull || []).map((item, idx) => {
              return (
                <MenuItem key={idx} value={item}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select> */}
        </FormControl>
      </div>
      <ListCustomerComponent
        // handleGetData={(params) => handleGetData(params)}
        getData={(params) => getCustomer(params)}
        getFanpage={getFanpage}
        data={data}
        fanpageSelected={fanpageSelected}
        detail={(item) => handleDetail(item)}
        edit={(item, params) => handleEdit(item, params)}
        handleExport={() => handleExport()}
        handleImport={() => handleImport()}
        handleFilter={(value) => handleFilter(value)}
        onCreate={(paramsFilter, rows, params, data) =>
          onCreate(paramsFilter, rows, params, data)
        }
        // openCreate={() => openCreate()}
        customerAll={customerAll}
        onCustomerCheck={onCustomerCheck}
        customerCheck={customerCheck}
        onCustomerCheckReset={onCustomerCheckReset}
        createCustomerSucss={createCustomerSucss}
        paramsFilter={paramsFilter}
        paramsPagination={(params) => handleParamsPagination(params)}
      />
      <FormDialog isOpen={isPopup} handleClose={() => handleClosePoup()}>
        <div className={cn(classes.wrapperPopup, 'p-4')}>
          <h4 className="mb-4">{`${
            isActions == types.ACTIONS.DETAIL
              ? t('update.cus_info.title_detail')
              : isActions == types.ACTIONS.FILTER
              ? t('customer_management.customer_filter.title')
              : t('update.cus_info.title_edit')
          }`}</h4>
          <Grid container>
            <Grid item xs={5}>
              <TextField
                error={false}
                label={`${isActions == types.ACTIONS.FILTER ? 'UID' : 'UID *'}`}
                defaultValue={
                  isActions == types.ACTIONS.FILTER ? '' : detail && detail.uid
                }
                variant="outlined"
                size="small"
                className={cn(classes.textField, 'mb-3')}
                disabled={
                  isActions == types.ACTIONS.DETAIL ||
                  isActions == types.ACTIONS.EDIT
                    ? true
                    : false
                }
                onChange={(event) => handleChange('uid', event.target.value)}
              />
              <TextField
                error={false}
                label={`${
                  isActions == types.ACTIONS.FILTER
                    ? t('customer_management.list.facebook_name')
                    : t('customer_management.list.facebook_name') + '*'
                }`}
                defaultValue={
                  isActions == types.ACTIONS.FILTER ? '' : detail && detail.name
                }
                variant="outlined"
                size="small"
                className={classes.textField}
                disabled={
                  isActions == types.ACTIONS.DETAIL ||
                  isActions == types.ACTIONS.EDIT
                    ? true
                    : false
                }
                onChange={(event) =>
                  handleChange('nameFacebook', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={7} style={{ position: 'relative' }}>
              <TextField
                error={false}
                label={t('customer_management.list.last_inbox_time')}
                defaultValue={
                  detail && detail.lastInboxTime
                    ? moment(detail.lastInboxTime).format('DD/MM/YYYY HH:mm')
                    : ''
                }
                variant="outlined"
                size="small"
                className={'w-100 mb-3'}
                disabled={true}
              />
              <TextField
                error={false}
                label={t('customer_management.list.last_customer_inbox_time')}
                defaultValue={
                  detail && detail.lastCustomerInboxTime
                    ? moment(detail.lastCustomerInboxTime).format(
                        'DD/MM/YYYY HH:mm'
                      )
                    : ''
                }
                variant="outlined"
                size="small"
                className={'w-100'}
                disabled={true}
              />
            </Grid>
          </Grid>
          {/* gioi tinh */}
          <RadioGroup
            defaultValue={detail && detail.gender}
            aria-label="gender"
            name="customized-radios"
            className={classes.wrapRadioGroup}
            style={{
              paddingTop: '4px',
              paddingBottom: '4px',
            }}
            disabled={
              isActions == types.ACTIONS.DETAIL ||
              isActions == types.ACTIONS.EDIT
                ? true
                : false
            }
          >
            <div
              className={classes.radioGroup}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <div className="mr-4">
                <span className="">{t('customer_management.list.gender')}</span>
              </div>
              <FormControlLabel
                value="all"
                control={<StyledRadio />}
                style={{
                  paddingTop: '6px',
                }}
                label={t('update.all')}
                disabled={
                  isActions == types.ACTIONS.DETAIL ||
                  isActions == types.ACTIONS.EDIT
                    ? true
                    : false
                }
                onChange={(event) => handleChange('gender', event.target.value)}
              />
              <FormControlLabel
                value="MALE"
                control={<StyledRadio />}
                label={t('update.gender.male')}
                disabled={
                  isActions == types.ACTIONS.DETAIL ||
                  isActions == types.ACTIONS.EDIT
                    ? true
                    : false
                }
                style={{
                  paddingTop: '6px',
                }}
                onChange={(event) => handleChange('gender', event.target.value)}
              />
              <FormControlLabel
                value="FEMALE"
                control={<StyledRadio />}
                label={t('update.gender.female')}
                disabled={
                  isActions == types.ACTIONS.DETAIL ||
                  isActions == types.ACTIONS.EDIT
                    ? true
                    : false
                }
                style={{
                  paddingTop: '6px',
                }}
                onChange={(event) => handleChange('gender', event.target.value)}
              />
              <FormControlLabel
                value="NEUTER"
                control={<StyledRadio />}
                label={t('update.gender.other')}
                disabled={
                  isActions == types.ACTIONS.DETAIL ||
                  isActions == types.ACTIONS.EDIT
                    ? true
                    : false
                }
                style={{
                  paddingTop: '6px',
                }}
                onChange={(event) => handleChange('gender', event.target.value)}
              />
            </div>
          </RadioGroup>
          <Flex container column>
            <Flex style={{ width: '100%' }}>
              <Grid item xs={5}>
                <FormControl
                  component="fieldset"
                  className={classes.formControlCheckbox}
                >
                  <FormLabel component="legend">
                    {t('customer_management.list.facebook_tag')}
                  </FormLabel>
                  {/* {listTagFacebook.length > 0 ? (
                      <FormGroup className={classes.tagFormGroup}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tagFbSelectedAll}
                              onChange={handleCheckedAllTagFacebook}
                              name={"allTagFacebook"}
                              disabled={
                                isActions == types.ACTIONS.DETAIL ? true : false
                              }
                            />
                          }
                          label={
                            <Chip
                              style={{
                                fontWeight: "bold"
                              }}
                              label={t("update.action.select_all")}
                            />
                          }
                        />
                        {(listTagFacebook || []).map((item, idx) => {
                          return (
                            <FormControlLabel
                              key={idx}
                              style={{ marginBottom: 3 }}
                              control={
                                <Checkbox
                                  checked={item.checked}
                                  onChange={() => handleCheckedTagFacebook(item)}
                                  name={item.id}
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  disabled={
                                    isActions == types.ACTIONS.DETAIL ? true : false
                                  }
                                />
                              }
                              label={
                                <Chip
                                  style={{
                                    background: `#${item.color}`,
                                    cursor: "pointer",
                                    color: item.color === "000000" ? "white" : ""
                                  }}
                                  label={
                                    item.name.length > 50
                                      ? `${item.name.slice(0, 50)}...`
                                      : item.name
                                  }
                                />
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    ) : (
                      <span className={classes.noData}>{t("update.no_data")}</span>
                    )} */}

                  <FormGroup className={classes.tagFormGroup}>
                    <VirtualScroll
                      rowHeight={30}
                      totalItems={listTagFacebook.length}
                      items={[
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tagFbSelectedAll}
                              disabled={true}
                              onChange={handleCheckedAllTagFacebook}
                              name={'allTagFacebook'}
                              // disabled={isActions == types.ACTIONS.DETAIL}
                            />
                          }
                          label={
                            <TagPreviewItem
                              styleText={{
                                maxWidth: '200px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontWeight: 'bold',
                              }}
                              background={'#ECEFF1'}
                              color={'#607D8B'}
                              name={t('update.action.select_all')}
                              size="medium"
                            />
                          }
                        />,
                        ...listTagFacebook.map((opt, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              style={{ marginBottom: 3 }}
                              control={
                                <div style={{ pointerEvents: 'none' }}>
                                  <Checkbox
                                    checked={opt.checked}
                                    onChange={() =>
                                      handleCheckedTagFacebook(opt)
                                    }
                                    name={opt.id}
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                    // disabled={
                                    //   isActions == types.ACTIONS.DETAIL
                                    //     ? opt.checked
                                    //     : false
                                    // }
                                    disabled={true}
                                  />
                                </div>
                              }
                              label={
                                <TagPreviewItem
                                  styleText={{
                                    maxWidth: '170px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  background={
                                    opt?.backgroundColor || `#${opt.color}`
                                  }
                                  color={opt?.textColor || '#FFF'}
                                  name={opt.name}
                                  size="medium"
                                />
                              }
                            />
                          )
                        }),
                      ]}
                      visibleItemsLength={50}
                      containerHeight={'200px'}
                    />
                  </FormGroup>

                  {/* {listTagFacebook.length > 0 ? (
                    <FormGroup className={classes.tagFormGroup}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tagFbSelectedAll}
                            disabled={true}
                            onChange={handleCheckedAllTagFacebook}
                            name={'allTagFacebook'}
                            // disabled={isActions == types.ACTIONS.DETAIL}
                          />
                        }
                        label={
                          <TagPreviewItem
                            styleText={{
                              maxWidth: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontWeight: 'bold',
                            }}
                            background={'#ECEFF1'}
                            color={'#607D8B'}
                            name={t('update.action.select_all')}
                            size="medium"
                          />
                        }
                      />
                      {(listTagFacebook || []).map((item, idx) => {
                        const colorCode = getRandomTag(colorDir1)

                        return (
                          <FormControlLabel
                            key={idx}
                            style={{ marginBottom: 3 }}
                            control={
                              <div style={{ pointerEvents: 'none' }}>
                                <Checkbox
                                  checked={item.checked}
                                  onChange={() =>
                                    handleCheckedTagFacebook(item)
                                  }
                                  name={item.id}
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  // disabled={
                                  //   isActions == types.ACTIONS.DETAIL
                                  //     ? item.checked
                                  //     : false
                                  // }
                                  disabled={true}
                                />
                              </div>
                            }
                            label={
                              <TagPreviewItem
                                styleText={{
                                  maxWidth: '170px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                                background={
                                  item?.backgroundColor || `#${item?.color}`
                                }
                                color={item?.textColor || '#FFF'}
                                name={item.name}
                                size="medium"
                              />
                            }
                          />
                        )
                      })}
                    </FormGroup>
                  ) : (
                    <span className={classes.noData}>
                      {t('update.no_data')}
                    </span>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item xs={7} style={{ position: 'relative' }}>
                <FormControl
                  component="fieldset"
                  className={classes.formControlCheckbox}
                >
                  <FormLabel component="legend">
                    {t('customer_management.list.bombot_tag')}
                  </FormLabel>

                  <FormGroup className={classes.tagFormGroup}>
                    <VirtualScroll
                      rowHeight={30}
                      totalItems={listTagFacebook.length}
                      items={[
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tagBbSelectedAll}
                              onChange={handleCheckedAllTagBombot}
                              name={'allTagBombot'}
                              style={{ paddingTop: 0, paddingBottom: 5 }}
                              disabled={
                                isActions == types.ACTIONS.DETAIL ? true : false
                              }
                            />
                          }
                          label={
                            <TagPreviewItem
                              styleText={{
                                maxWidth: '250px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontWeight: 'bold',
                              }}
                              background={'#ECEFF1'}
                              color={'#607D8B'}
                              name={t('update.action.select_all')}
                              size="medium"
                            />
                          }
                        />,
                        ...(listTagBombot || []).map((item, idx) => {
                          return (
                            <div className={classes.wrapCheckbox} key={idx}>
                              <FormControlLabel
                                style={{ marginBottom: 3 }}
                                control={
                                  <Checkbox
                                    checked={item.checked}
                                    onChange={() =>
                                      handleCheckedTagBombot(item)
                                    }
                                    name={item.id}
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                    disabled={
                                      isActions == types.ACTIONS.DETAIL
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={
                                  <TagPreviewItem
                                    styleText={{
                                      maxWidth: '250px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    background={
                                      item?.backgroundColor || `#${item?.color}`
                                    }
                                    color={item?.textColor || '#FFF'}
                                    name={item.name}
                                    size="medium"
                                  />
                                }
                              />
                              {isActions == types.ACTIONS.EDIT && (
                                <span
                                  className={classes.delete}
                                  onClick={() => handleDeteleTag(item)}
                                >
                                  <DeleteIcon />
                                </span>
                              )}
                            </div>
                          )
                        }),
                      ]}
                      visibleItemsLength={50}
                      containerHeight={'200px'}
                    />
                  </FormGroup>

                  {/* {listTagBombot.length > 0 ? (
                    <FormGroup className={classes.tagFormGroup}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tagBbSelectedAll}
                            onChange={handleCheckedAllTagBombot}
                            name={'allTagBombot'}
                            style={{ paddingTop: 0, paddingBottom: 5 }}
                            disabled={
                              isActions == types.ACTIONS.DETAIL ? true : false
                            }
                          />
                        }
                        label={
                          <TagPreviewItem
                            styleText={{
                              maxWidth: '250px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontWeight: 'bold',
                            }}
                            background={'#ECEFF1'}
                            color={'#607D8B'}
                            name={t('update.action.select_all')}
                            size="medium"
                          />
                        }
                      />
                      {(listTagBombot || []).map((item, idx) => {
                        return (
                          <div className={classes.wrapCheckbox} key={idx}>
                            <FormControlLabel
                              style={{ marginBottom: 3 }}
                              control={
                                <Checkbox
                                  checked={item.checked}
                                  onChange={() => handleCheckedTagBombot(item)}
                                  name={item.id}
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  disabled={
                                    isActions == types.ACTIONS.DETAIL
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={
                                <TagPreviewItem
                                  styleText={{
                                    maxWidth: '250px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                  background={
                                    item?.backgroundColor || `#${item?.color}`
                                  }
                                  color={item?.textColor || '#FFF'}
                                  name={item.name}
                                  size="medium"
                                />
                              }
                            />
                            {isActions == types.ACTIONS.EDIT && (
                              <span
                                className={classes.delete}
                                onClick={() => handleDeteleTag(item)}
                              >
                                <DeleteIcon />
                              </span>
                            )}
                          </div>
                        )
                      })}
                    </FormGroup>
                  ) : (
                    <span className={classes.noData}>
                      {t('update.no_data')}
                    </span>
                  )} */}

                  {isActions == types.ACTIONS.EDIT && (
                    <div className="w-auto">
                      <button
                        className="d-flex justify-content-start mt-4 px-3 py-2 text-uppercase border-0 rounded-lg"
                        style={{
                          transition: 'background-color 0.3s',
                          cursor: 'pointer',
                        }}
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = '#f0f0f0')
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = 'white')
                        }
                        onClick={() => handleOpenAddTag()}
                      >
                        {t('update.action.add_tag')}
                      </button>
                    </div>
                  )}
                </FormControl>
              </Grid>
            </Flex>
          </Flex>
          <FormControl className={classes.wrapButton}>
            {/* <Flex justify="flex-between" className="mt-2">
              <span
                style={{
                  color: "rgb(233, 30, 99)",
                  fontSize: "11px",
                  textAlign: "center",
                  padding: "10px",
                  borderRadius: "4px"
                }}
              >
                {"Lưu ý: Tính năng đồng bộ tag fanpages chưa được hỗ trợ"}
              </span>
              <ButtonComponent
                title={
                  <span className="text-uppercase">
                    {t("common.btn.cancel")}
                  </span>
                }
                color="white"
                handleClickButton={() => handleClosePoup()}
              />
              {isActions != types.ACTIONS.DETAIL && (
                <ButtonComponent
                  className={cn("btn--rounder", "btn--primary--filled", "ml-2")}
                  title={
                    <span className="text-uppercase">
                      {t("common.btn.save")}
                    </span>
                  }
                  color="white"
                  handleClickButton={() => handleSubmitActions()}
                />
              )}
            </Flex> */}
            <Flex justify="space-between" align="center" className="mt-2">
              <div>
                <span
                  style={{
                    color: 'rgb(233, 30, 99)',
                    fontSize: '11px',
                    textAlign: 'center',
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                >
                  {t('dashboard.sync_to_fanpage_not_supported')}
                </span>
              </div>

              <Flex align="center" gap={8}>
                <ButtonComponent
                  title={
                    <span className="text-uppercase">
                      {t('common.btn.save')}
                    </span>
                  }
                  color="white"
                  backgroundColor="#05AE34"
                  className="btn--primary--filled mr-2"
                  handleClickButton={() => handleSubmitActions()}
                  style={{
                    minWidth: 'fit-content',
                  }}
                />
                <ButtonComponent
                  title={
                    <span className="text-uppercase">
                      {t('common.btn.cancel')}
                    </span>
                  }
                  color="white"
                  handleClickButton={() => handleClosePoup()}
                  style={{
                    minWidth: 'fit-content',
                  }}
                />
              </Flex>
            </Flex>
          </FormControl>
        </div>
      </FormDialog>
      {importResult && <ModalResultImport data={importResult} />}
      <MessengerButton />
    </MainLayout>
  )
}

function InputComponent({ defaultValue, inputRef, ...props }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(null)
  const handleChange = (event) => {
    setValue(event.target.value)
    if (props.onChange) {
      props.onChange(event)
    }
  }
  return (
    <div className={classes.root} style={{ width: '100%' }}>
      <div
        className={classes.display}
        style={{
          display: 'inline-block',
          position: 'absolute',
          background: 'white',
          top: '0px',
          height: '40px',
          width: '100px',
          lineHeight: '40px',
        }}
      >
        {value != null && moment(value).format('DD/MM/YYYY')}
      </div>
      <input
        className={classes.input}
        ref={inputRef}
        {...props}
        onChange={handleChange}
        value={moment(value).format('YYYY/MM/DD')}
      />
    </div>
  )
}

CustomerView.propTypes = {}

CustomerView.defaultProps = {}

export default CustomerView
