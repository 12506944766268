import { createSlice } from '@reduxjs/toolkit'
import AppConfigAPI from 'api/appConfig'

export const configSlice = createSlice({
  name: 'configs',
  initialState: {
    loading: false,
    error: null,
    guides: null,
    countries: [],
  },
  reducers: {
    startGetGuide: (state) => {
      state.loading = true
      state.error = false
    },
    getGuideSuccess: (state, action) => {
      state.loading = false
      state.guides = action.payload
    },
    getGuideFailed: (state) => {
      state.loading = false
    },
    startGetCountries: (state) => {
      state.loading = true
      state.error = false
    },
    getCountriesSuccess: (state, action) => {
      state.loading = false
      state.countries = action.payload || []
    },
    getCountriesFailed: (state) => {
      state.loading = false
    },
  },
})

export const {
  startGetGuide,
  getGuideSuccess,
  getGuideFailed,
  startGetCountries,
  getCountriesSuccess,
  getCountriesFailed,
} = configSlice.actions

export const getGuide = (params) => async (dispatch) => {
  try {
    dispatch(startGetGuide())
    const res = await AppConfigAPI.getGuide(params)
    dispatch(getGuideSuccess(res.content?.value))
  } catch (error) {
    dispatch(getGuideFailed(error))
  }
}

export const getCountries = (params) => async (dispatch) => {
  try {
    dispatch(startGetCountries())
    const res = await AppConfigAPI.getCountry(params)
    dispatch(getCountriesSuccess(res.content?.value))
  } catch (error) {
    dispatch(getCountriesFailed(error))
  }
}

export default configSlice.reducer
