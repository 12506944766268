import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  close: {
    position: 'absolute',
    top: 15,
    right: 15,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default function CustomizedDialogs(props) {
  const {
    isOpen,
    handleClose,
    children,
    transitionDelay,
    width,
    withoutHeader,
    ...rest
  } = props
  const classes = useStyles()
  return (
    <Dialog
      maxWidth={false}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      TransitionProps={{
        style: {
          transitionDelay: transitionDelay ? 3000 : 0,
        },
      }}
      {...rest}
    >
      {handleClose && !withoutHeader && (
        <CloseIcon className={classes.close} onClick={handleClose} />
      )}
      <div style={{ width: width || '650px' }}>{children}</div>
    </Dialog>
  )
}
